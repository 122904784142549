<template>
    <div>
        <CCard>
            <CCardHeader>
                {{ title }}
            </CCardHeader>
            <CCardBody>
                <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="userFields" :canEdit="canEdit" />
            </CCardBody>
        </CCard>
        <CCard>
            <AbstractTable :fields="fields" :items="patients" :actions="actions" title="Pacientes asociados"
                labelItemPerPage="Pacientes por página:" 
                :addBtn="{}" @seeInfo="handleInfo" @showDeleteModal="handleModal"
                :noResults="'No se han encontrado cuestionarios'" />
        </CCard>
        <DeleteModal :modalContent="modalContent" @delete="deleteQuestData(patientId)" :showModal="deleteModal"
            @closeModal="closeModal" />
    </div>
</template>

<script>
import { getDoctorData, updateDoctor, getPatientsDoctorData } from "@/services/doctors";
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import AbstractForm from '@/components/Forms/AbstractForm.vue';
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import { generateField } from '@/utils/utils';
export default {
    name: 'formDoctor',
    components: {
        AbstractTable, AbstractForm, DeleteModal,
    },
    data(){
     return{
        title: null,
        id: null,
        deleteModal: false,
        editDataDoctor: {},
        patients: [],
        patientId: 0,
        patientName: '',
        patientSurName: '',
        formContent: {
            buttons: [
                {
                    textButton: 'Volver',
                    color: 'secondary',
                    event: 'back',
                    style: 'margin: 10px',
                    active: true
                },
                {
                    textButton: 'Editar',
                    color: 'success',
                    event: 'edit',
                    style: 'margin: 10px',
                    active: this.$store.state.isAdmin
                },
            ],
            rows: [
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'name',
                                label: 'Nombre',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'surname',
                                label: 'Apellidos',
                                maxLength: 30,
                            },
                        },
                    ]
                },
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'input',
                                value: '',
                                key: 'email',
                                label: 'Email',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'date',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'date',
                                value: '',
                                key: 'birthdate',
                                label: 'Fecha de nacimiento',
                                maxLength: 30,
                            },
                        },
                    ]
                },
                {
                    style: '',
                    class: '',
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'select',
                                value: '',
                                key: 'gender',
                                label: 'Género',
                                maxLength: 30,
                                options:
                                    [
                                        { name: 'Hombre', value: "male" },
                                        { name: 'Mujer', value: "female" },
                                        { name: 'Otros', value: "other" }
                                    ],
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: '',
                            class: '',
                            content: {
                                type: 'textArea',
                                value: '',
                                key: 'description',
                                label: 'Descripcion',
                                maxLength: 1000,
                            },
                        },
                    ]
                },
                {
                    style: [],
                    class: [],
                    content: [
                        {
                            type: 'element',
                            rule: [],
                            style: [],
                            class: [],
                            content: {
                                type: 'input',
                                value: '',
                                key: 'weight',
                                label: 'Peso',
                                maxLength: 30,
                            },
                        },
                        {
                            type: 'element',
                            rule: [],
                            style: [],
                            class: [],
                            content: {
                                type: 'input',
                                value: '',
                                key: 'height',
                                label: 'Altura',
                                maxLength: 30,
                            },
                        },
                    ]
                },
            ],
        },
        canEdit: true,
        GENDER_MEN: "Hombre",
        GENDER_WOMAN: "Mujer",
        GENDER_OTHER: "Otros",
        doctor: {},
        contAlertParams: 0,
        submitButtonText: "Crear",
        backButtonText: "Volver",
        errorText: "Campos vacios o erroneos.",
        fields: [
            generateField("name", "Nombre"),
            generateField("surname", "Apellidos"),
            generateField("email", "Email"),
            generateField("gender", "Género"),
            generateField("weight", "Peso"),
            generateField("height", "Altura"),
            generateField("actions", "Acciones", { sorter: false, filter: false }),
        ],
        userFields: {
            name: null,
            surname: null,
            email: null,
            birthdate: null,
            gender: null,
            description: null,
            weight: null,
            height: null
        },
        modalContent: {
            title: 'Eliminar paciente',
            textBody: '',
            buttons: [
                {
                    textButton: 'Cerrar',
                    color: 'secondary',
                    event: 'closeModal'
                },
                {
                    textButton: 'Aceptar',
                    color: 'danger',
                    event: 'delete'
                },
            ]
        },
        actions: [
            {
                key: 'info',
                label: 'Información',
                event: 'seeInfo',
                classButton: 'btn btn btn-info btn-success btn-sm btn-pill'
            },
            {
                key: 'delete',
                label: 'Eliminar',
                event: 'showDeleteModal',
                classButton: 'btn btn-delete btn-sm btn-pill'
            },
        ],
        msg: '',
        isVisible: false,
        genders: [
            { name: 'Hombre', value: "male" },
            { name: 'Mujer', value: "female" },
            { name: 'Otros', value: "other" }
        ],
        doctorToEdit: null
        }
    },
    created() {
        this.receiveData();
    },
    methods: {
        onEmitMethods(event, dataDoctor) {
            switch (event) {
                case 'edit': this.editDoctor(dataDoctor); break;
                case 'back': this.back(); break;
                default: break;
            }
        },
        receiveData() {
            this.checkId();
            if (this.id) {
                this.getDoctor();
                this.getPatients();
            }
        },
        getPatients() {
            getPatientsDoctorData(this.id)
                .then((result) => {
                    this.patients = result;
                    this.patients.forEach((patient) => {
                    if (patient.gender == "male") {
                      patient.gender = this.GENDER_MEN;
                    } else if (patient.gender == "female") {
                      patient.gender = this.GENDER_WOMAN;
                    } else {
                      patient.gender = this.GENDER_OTHER;
                    }
                      patient.weight = patient.weight + " kg";
                    if (patient.height > 100) {
                      patient.height = patient.height / 100 + " m";
                    } else {
                      patient.height = patient.height + " m";
                    }
                });
                })
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
        },
        checkId() {
            this.id = this.$route.params.id;
        },

        checkInputs(dataDoctor) {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const numericRegex = /^[0-9]+$/; // Expresión regular para validar números enteros
            const validationRules = [
                { field: 'name', message: 'Introduzca el nombre (máximo de 30 caracteres).', maxLength: 30 },
                { field: 'surname', message: 'Introduzca el apellido (máximo de 20 caracteres).', maxLength: 20 },
                { field: 'email', message: 'Introduzca un email válido.', regex: emailRegex },
                { field: 'birthdate', message: 'Introduzca la fecha de nacimiento.' },
                { field: 'gender', message: 'Seleccione un género.', value: '' },
                { field: 'description', message: 'Introduzca una descripción (máximo de 1000 caracteres).', maxLength: 1000 },
                { field: 'weight', message: 'Introduzca el peso en kilogramos (sólo valores enteros).', regex: numericRegex, minValue: 0 },
                { field: 'height', message: 'Introduzca la altura en centímetros (sólo valores enteros).', regex: numericRegex, minValue: 0 },
            ];
            for (const rule of validationRules) {
                const value = dataDoctor[rule.field];
                if (!value || (rule.maxLength && value.length > rule.maxLength) ||
                    (rule.minLength && value.length < rule.minLength) ||
                    (rule.noSpaces && value.includes(' ')) ||
                    (rule.value && value === rule.value) ||
                    (rule.minValue && parseInt(value) < rule.minValue) ||
                    (rule.equalField && value !== dataDoctor[rule.equalField]) ||
                    (rule.regex && !rule.regex.test(value))) {
                    this.showAlertError(rule.message);
                    return false;
                }
            }
            return true;
        },
        showAlertError(msg) {
            this.$store.state.errorAlert = { status: true, msg: msg.toString() }
        },
        editDoctor(dataDoctor) {
            if (this.checkInputs(dataDoctor) == false) {
                return;
            }
            updateDoctor(dataDoctor, this.id)
                .then(() => {
                    this.$router.push("/doctor")
                })
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
        },
        getDoctor() {
            if (!this.id) return;
            let data = getDoctorData(this.id);
            data.then(result => {
                this.userFields = result;
                this.doctorGenderValue(result.gender);
                this.title = `Información del doctor ${this.userFields.name} ${this.userFields.surname}`;
            }).catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() } })
        },
        doctorGenderValue(doctor_gender) {
            const gender = this.genders.find(i => i.value == doctor_gender);
            this.userFields.gender = gender.value;
        },
        /**
         * Este método se llama cuando el usuario pulsa el botón Ver contenido y redirige a la vista para poder consultar la información del cuestionario.
         */
        handleInfo(item) {
            this.$router.push(`/patient/info/${item.id}`)
        },
        /**
          * Método para controlar lo que se muestra en el Modal de Eliminar paciente.
          */
        handleModal(item) {
            this.patientId = item.id;
            this.modalContent.textBody = `¿Está seguro de que desea eliminar el paciente: <strong>"${item.name} ${item.surname}</strong>"? Esta acción no se puede deshacer.`,
                this.deleteModal = true;
        },
        /**
     * Función para cerrar el Modal.
     */
        closeModal() {
            this.deleteModal = false;
        },
        /**
         * Función para volver atrás
         */
        back() {
            this.$router.push("/doctor");
        }
    }
}
</script>

<style scoped>
.table-wrapper-title {
    padding: 10px 25px;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 20px;
}

@media (max-width: 768px) {
    .table-wrapper .card-body {
        padding: 0;
    }

    .table-wrapper-title {
        padding: 10px;
        margin-bottom: 0;
        margin-top: 0px;
        font-size: 18px;
    }
}
</style>